import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import logo from '../logo.svg';
import '../css/App.css';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}


export const SignInButton = () => {
    const { instance } = useMsal();
    return (
        <Button variant="secondary" className="loginBtn" onClick={() => handleLogin(instance)}>Sign In</Button>
    );
}