import React, { useEffect,useState }  from "react";
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Button from "react-bootstrap/Button";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import logo from './logo.svg';
import axios from 'axios'

function App() {
  return (
      <PageLayout>
           <AuthenticatedTemplate>
           <PrivateArea />
            </AuthenticatedTemplate>
         <UnauthenticatedTemplate>
           <PublicArea />
            </UnauthenticatedTemplate>
      </PageLayout>
  );
}
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// START of PUBLIC AREA

function PublicArea() {
  const [currentTime, setCurrentTime] = useState("");
  const [userIP, setUserIP] = useState('');

  useEffect(() => {
    // Create a Date object
    const date = new Date();

    // Extract time components
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Format the time
    const formattedTime = `${hours}:${minutes}`;

    // Update the state
    setCurrentTime(formattedTime);

    // Set up an interval to update the time every minute
    const timer = setInterval(() => {
      const updatedDate = new Date();
      const updatedHours = updatedDate.getHours();
      const updatedMinutes = updatedDate.getMinutes().toString().padStart(2, "0");
      const updatedFormattedTime = `${updatedHours}:${updatedMinutes}`;
      setCurrentTime(updatedFormattedTime);
    }, 60 * 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // Fetch user's IP address
    axios.get('https://api.ipify.org/?format=json')
      .then((response) => {
        setUserIP(response.data.ip);
        console.log('User IP extracted')
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, []);
return(
    <div className="publicArea">
     <div className="logo-container">
     <code className="techbytesTitle-public">techbytes</code>
     <img src={logo} className="App-logo" alt="logo" />
     </div>
        <p className="publicDataTiny">v10</p>
<ul className="publicData">
  <li>Current Time: {currentTime}</li>
  <li>Connected IP: {userIP}</li>
</ul>

    </div>
)
}
// END of PUBLIC AREA
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Start of PRIVATE AREA
function PrivateArea() {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const name = accounts[0] && accounts[0].name;
  const [buttonText, setButtonText] = useState('Show Profile');

  function RequestProfileData() {
      const request = {
          ...loginRequest,
          account: accounts[0]
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
          callMsGraph(response.accessToken).then(response => setGraphData(response));
          instance.acquireTokenSilent(request)
          .then((response) => {
            callMsGraph(response.accessToken)
              .then(response => {
                setGraphData(response);
                setButtonText('Hide Profile');
              });
          })
      }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then(response => setGraphData(response));
          });
      });
  }

  return (
      <>
          <nav className="navBar">
          <div className="navBar-container-private">
        <div className= "navbar-links">
        <a
          className="App-link"
          href="https://pmx.techbytes.me"
          target="_blank"
          rel="noopener noreferrer"
        >
          PMX
        </a>
        <a
          className="App-link"
          href="https://esxi.techbytes.me"
          target="_blank"
          rel="noopener noreferrer"
        >
          ESXI
        </a>
        <a
          className="App-link"
          href="https://nas.techbytes.me"
          target="_blank"
          rel="noopener noreferrer"
        >
          NAS
        </a>
        </div>
        </div>
        </nav>
       
        
          <div className="welcome-Tag">
          <img src={logo} className="logo-private" alt="logo" />
          <h1 className="techbytesTitle">techbytes</h1>
          <h5 className="welcome-Tagline" >Welcome {name}</h5>
          <div className="profileContent"> 
      {buttonText === 'Show Profile' ? (
          <Button variant="secondary" className="profileBtn" onClick={RequestProfileData}>
            {buttonText}
          </Button>
        ) : (
          <>
            <Button variant="secondary" className="profileBtn" onClick={() => setButtonText('Show Profile')}>
              Hide Profile
            </Button>
            <ProfileData graphData={graphData} />  {/* Render ProfileData when buttonText is Hide Profile */}
          </>
        )}
          </div>
          </div>

      </>
  );
};
// END of PRIVATE AREA
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export default App;
