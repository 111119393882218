import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import '../css/App.css';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
        <div className="App">
        <header className="app-header">
        <div className="header-content">
          <nav className="navBar">
            <div className="navBar-container">
          <div className="sign-in-out-container">
        { isAuthenticated ? <SignOutButton /> : <SignInButton /> }

            </div>
        </div>
          </nav>
        </div>
        </header>
        {props.children}
      </div>
      </>
    );
};