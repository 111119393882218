export const msalConfig = {
    auth: {
      clientId: "4fe85d07-505a-4f78-8008-db2dfa28d25b",
      authority: "https://login.microsoftonline.com/ac48bae2-8901-44c1-a85e-ff7c2a50dfb1", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://web.techbytes.me",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };
